<template>
  <v-container>
    <v-row>
      <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
      <v-col class="col-12 col-sm-8 pa-5">
        <h1>Honor Tag Code of Conduct</h1>
        <h3>Overview</h3>
        Honor Tag is an online community dedicated to recognizing our veterans. Whether in times of war or times of peace, Honor Tag strives to tell the stories of those who served, both living and dead. We believe that families, friends and fellow service members should be empowered to foster and grow the Honor Tag community. We strive to give these individuals the tools and technology needed to memorialize service people across all branches of the military.<br>
        <br>
        Given this understanding, Honor Tag has developed The Honor Tag Code of Conduct which is entered into by all Honor Tag members when they register an account.<br>
        <br>
        <b>Trust and respect are the cornerstones of Honor Tag. As an Honor Tag member, you commit to upholding the values of Honesty, Community, Respect, and Diligence in all of your interactions within the Honor Tag platform and its community members. You will act with integrity and strive to embody the highest ideals of citizenship and responsibility within the community.</b><br>
        <br>
        The Honor Tag Code of Conduct was created to act as an overarching statement of honor, which speaks to the philosophy that “honor” is holistic in nature embodying accuracy of content and social responsibility. In as much as our beloved service members adhere to the code of conduct for their respective military branch, so too shall Honor Tag members, in engendering respect for our veterans, conduct themselves accordingly.<br>
        <br>
        <h3>Purpose</h3>
        The Code of Conduct is foundational to Honor Tag and exists for the priority of preserving:<br>
        <ul>
          <li>Your safety</li>
          <li>Your privacy</li>
          <li>Integrity and accuracy of content on the site</li>
          <li>Respect among community members</li>
        </ul>
        <h3>Honor Tag Values</h3>
        These values are core tenets of the Honor Tag platform and the community of people who devote time and effort to contributing and curating content. We ask that all members of Honor Tag agree to the following values as a condition of membership within the platform:<br>
        <br>
        <b>Honesty</b> - Be truthful in all contributions to Honor Tag. Do and say nothing that deceives others. When in doubt, verify your sources and be certain about the content you share. Honor Tag and its members are extremely sensitive to cases of Stolen Valor. Community members are expected to exercise due care and discretion in selecting achievements for veteran stories. Where possible, community members should upload supporting documentation for medals and ranks. Community members are able to flag instances of Stolen Valor for prompt correction or removal.<br>
        <br>
        <b>Community</b> - Honor Tag is larger than just one person. In contributing to Honor Tag, you are supporting a greater community without thought of recognition or gain. Each member should always consider how he or she can add to the effort of growing and fostering the Honor Tag community.<br>
        <br>
        <b>Respect</b> - Treat other Honor Tag members with dignity and respect while expecting others to do the same. Respect allows us to put forth our best effort and appreciate one another’s contributions. People of all ages and backgrounds use Honor Tag and as such, it is imperative that political statements and obscene language are strictly avoided. Further, any hate speech or imagery are considered a violation of this code.<br>
        <br>
        <b>Diligence</b> - Put forth an earnest and energetic effort toward building the Honor Tag community and growing the base of information on the platform. Ensure accuracy in your contributions and encourage the same in others.<br>
        <br>
        <h3>Violations</h3>
        Honor Tag takes violations of this Code of Conduct very seriously. We may take any action regarding your access to and/or use of the community, or site as it deems appropriate in connection with a violation of this Code of Conduct, including, but not limited to, removal of your posts or other content, revoking your subscription with no refund, or removing your account from any or all of our services.<br>
        <br>
        <h3>Reporting Violations</h3>
        All reports of violations should be submitted to info@honor-tag.com and will be reviewed to determine the appropriate course of action in a timely manner.<br>
      </v-col>
      <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Code'
  }
</script>
